import React, { FC, useEffect } from 'react';
import Link from 'next/link';
import Aos from 'aos';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
interface Props {
  companyInfo?: any;
  year?: any;
}

const Footer: FC<Props> = ({ companyInfo, year }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="z-100">
        <div className="bg-gradient-to-tr from-[#133294] to-[#0f266e] text-gray-200">
          <div className={`max-w-7xl mx-auto px-4 pt-12`}>
            <div className="grid grid-cols-2 gap-10 lg:gap-0 lg:grid-cols-4">
              <div className="col-span-2 lg:col-span-1" data-aos="fade-right">
                <div className={`text-2xl font-semibold text-white/60`}>
                  Eduline Co.,Ltd
                </div>
                <p className="mt-6 w-60 text-sm">
                  Зөв сонголт, Ирээдүйн баталгаа {year} жилийн туршлагатай
                  боловсрол зуучлалын байгууллага
                </p>
                <div className="flex mt-6 ">
                  <a target="_blank" href="https://www.facebook.com/edulinellc">
                    <img
                      src="/assets/social/facebook.png"
                      className="mr-5 h-8 w-8 text-white hover:scale-105"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/eduline_llc/"
                  >
                    <img
                      src="/assets/social/instagram.png"
                      className="mr-5 h-8 w-8 hover:scale-105"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/c/edulinellcmongolia"
                  >
                    <img
                      src="/assets/social/youtube.png"
                      className="mr-5 h-8 w-8 transform hover:scale-105"
                    />
                  </a>
                </div>
              </div>
              <div>
                <h1 className="text-white/80 text-base" data-aos="fade-up">
                  Компаны тухай
                </h1>
                <div className="mt-6 text-sm text-white/80">
                  {companyInfo?.map(company => (
                    <Link key={company.id} href={company?.head}>
                      <a target="_blank" className="hover:underline">
                        <p className="mt-2 ">-{`${company?.title}`}</p>
                      </a>
                    </Link>
                  ))}
                </div>
              </div>

              <div>
                <h1 className="text-white/80 text-base" data-aos="fade-up">
                  Боловсролын зууч
                </h1>
                <div className="mt-6 text-sm text-white/80">
                  <Link href={'http://edulinellc.mn/'}>
                    <a target="_blank" className="hover:underline">
                      <p className="mt-2">Нүүр хуудас</p>
                    </a>
                  </Link>
                  <Link href={'http://edulinellc.mn/korea/'}>
                    <a target="_blank" className="hover:underline">
                      <p className="mt-2">Солонгос</p>
                    </a>
                  </Link>
                  <Link href={'http://edulinellc.mn/usa/'}>
                    <a target="_blank" className="hover:underline">
                      <p className="mt-2">Америк</p>
                    </a>
                  </Link>
                  <Link href={'http://edulinellc.mn/irland/'}>
                    <a target="_blank" className="hover:underline">
                      <p className="mt-2">Ирланд</p>
                    </a>
                  </Link>
                  <Link href={'http://edulinellc.mn/philippine/'}>
                    <a target="_blank" className="hover:underline">
                      <p className="mt-2">Филиппин</p>
                    </a>
                  </Link>
                  <Link href={'http://edulinellc.mn/academy/'}>
                    <a target="_blank" className="hover:underline">
                      <p className="mt-2">Хэлний сургалт</p>
                    </a>
                  </Link>
                </div>
              </div>

              <div className="col-span-2 lg:col-span-1">
                <h1 className="text-white/80 text-base" data-aos="fade-up">
                  Холбоо барих{' '}
                </h1>

                <div className="mt-6 mr-0 lg:mr-10 space-y-2 text-sm text-white/80">
                  <div>
                    <a
                      href="mailto:info@edulinellc.mn"
                      className="flex items-center justify-start"
                    >
                      <EnvelopeIcon className="h-6 w-7" />
                      <b className="pr-1 font-normal text-white/80">
                        {'Имэйл'}:
                      </b>
                      info@edulinellc.mn
                    </a>
                  </div>
                  <hr className="border-dashed mt-2 pb-1 border-white/20" />
                  <div>
                    <div className="flex items-start">
                      <img
                        src="/assets/flagMongolia.png"
                        className="h-4 w-6 mt-1"
                      />
                      <b className="px-2 font-normal text-white/80">
                        {'Хаяг'}:
                      </b>
                      {
                        'Улаанбаатар, Чингэлтэй дүүрэг, 1-р хороо, Жуулчны гудамж, Дельта төв, 3 давхар – 302'
                      }
                    </div>
                    <div className="flex items-start pl-6">
                      <b className="px-2 font-normal text-white/80">
                        {'Утас'}:
                      </b>
                      <div className="flex items-center flex-wrap">
                        <a href="telno:70110503">7011-0503</a>,
                        <a href="11320535">11-320-535</a>
                      </div>
                    </div>
                    <hr className="border-dashed mt-2 pb-1 border-white/20" />
                  </div>
                  <div>
                    <div className="flex items-start">
                      <img
                        src="https://i2.wp.com/edulinellc.mn/wp-content/uploads/2020/08/kr-flag-mini.png?resize=32%2C21"
                        className="h-4 w-6 mt-1"
                      />
                      <b className="px-2 font-normal text-white/80">
                        {'Хаяг'}:
                      </b>
                      {'Seoul, Gangseo-gu, Yangcheon-ro 551-24, 305'}
                    </div>
                    <div className="flex items-start pl-6">
                      <b className="px-2 font-normal text-white/80">
                        {'Утас'}:
                      </b>
                      <div className="flex items-center flex-wrap">
                        <a href="telno:01066323266">010-6632-3266</a>,{' '}
                        <a href="07071511289"> 070-7151-1289</a>
                      </div>
                    </div>
                    <hr className="border-dashed mt-2 pb-1 border-white/20" />
                  </div>
                  <div>
                    <div className="flex items-start">
                      <img
                        src="https://i0.wp.com/edulinellc.mn/wp-content/uploads/2020/08/vn.png?resize=32%2C21"
                        className="h-4 w-6 mt-1"
                      />
                      <b className="px-2 font-normal text-white/80">
                        {'Хаяг'}:
                      </b>
                      {
                        'Hanoi, Nam Tu Liem District, Pham Phuong Streete, Song Da Building, Floor 26'
                      }
                    </div>
                    <div className="flex items-start pl-6">
                      <b className="px-2 font-normal text-white/80">
                        {'Утас'}:
                      </b>
                      <a href="telno:84354649571">84-35-464-9571</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center text-sm text-white/60 py-4 border-t border-white/20 mt-4 px-4">
            © Зохиогчийн эрхийг хуулийн дагуу Эдүлайн ХХК эзэмшинэ.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
