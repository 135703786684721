import { Transition } from '@headlessui/react';
import CompanyInfo from '@data/company.json';
import React, { useState } from 'react';
import Link from 'next/link';
import PhoneCall from '../phoneCall';
import Profile from '../profile';
import { useRouter } from 'next/router';
import { useAuthState } from '@context/auth';
import RegisterModal from '@components/register/registerModal';
import {
  UserIcon,
  XMarkIcon,
  UserCircleIcon,
  ChevronRightIcon,
  PencilIcon,
  ChartBarIcon,
  BeakerIcon,
  DocumentIcon,
  StarIcon,
  ClipboardDocumentCheckIcon,
  Bars3BottomLeftIcon,
} from '@heroicons/react/24/outline';
import ChatIconSolid from '@components/ui/chatIconSolid';

export default function Navbar() {
  const router = useRouter();
  const { pathname } = router;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNavbar, setIsOpenNavbar] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const {
    isAuthenticated: isLoggedIn,
    user: currentUser,
    setLogout,
  } = useAuthState();

  function logOut() {
    setLogout();
    router.push('/');
  }

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setIsOpenNavbar(true);
    } else {
      setIsOpenNavbar(false);
    }
  };
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', changeBackground);
  }

  return (
    <div className="w-full">
      <nav
        className={`top-0 z-20 fixed w-full border-b border-opacity-10 ${
          pathname === '/'
            ? isOpenNavbar
              ? 'backdrop-blur-2xl bg-white/95 shadow-lg'
              : 'backdrop-blur-2xl bg-transparent/10'
            : 'bg-gradient-to-r from-[#3858c0] to-[#3937b3] shadow-lg'
        }  `}
      >
        <div
          className={`max-w-7xl mx-auto flex justify-between p-4 items-center`}
        >
          <div className="flex items-center">
            <Link href="/">
              <a>
                <div className="cursor-pointer flex items-center mr-4">
                  <img
                    src="/assets/home/mainlogo.png"
                    alt=""
                    className="h-12 lg:h-14"
                  />
                </div>
              </a>
            </Link>
            <div
              className={`md:flex items-center font-semibold hidden text-sm text-white cursor-pointer ml-4 ${
                pathname === '/'
                  ? isOpenNavbar
                    ? 'text-bluec'
                    : 'text-white'
                  : 'text-white'
              }`}
            >
              {isLoggedIn && (
                <>
                  <Link href="/step">
                    <div
                      className={`bg-none hover:backdrop-blur-2xl ${
                        pathname === '/'
                          ? isOpenNavbar
                            ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                            : 'hover:bg-white/30'
                          : 'hover:bg-white/20'
                      }  px-3 py-2.5 rounded`}
                    >
                      Оюутан
                    </div>
                  </Link>
                  <Link href="/sim">
                    <div
                      className={`bg-none  hover:backdrop-blur-2xl ${
                        pathname === '/'
                          ? isOpenNavbar
                            ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                            : 'hover:bg-white/30'
                          : 'hover:bg-white/20'
                      }  px-3 py-2.5 rounded`}
                    >
                      Сим
                    </div>
                  </Link>
                </>
              )}
              {isLoggedIn && (
                <Link href="/interesting-news">
                  <a>
                    <div
                      className={`${
                        pathname === '/interesting-news'
                          ? 'bg-white/20'
                          : pathname === '/' && isOpenNavbar
                          ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                          : 'hover:bg-white/30'
                      } bg-none hover:backdrop-blur-2xl px-3 py-2.5 rounded`}
                    >
                      Зөвлөгөө
                    </div>
                  </a>
                </Link>
              )}
              <Link href="/news">
                <a>
                  <div
                    className={`${
                      pathname === '/news' || pathname === '/news/[value]'
                        ? 'bg-white/20'
                        : pathname === '/' && isOpenNavbar
                        ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                        : 'hover:bg-white/30'
                    } bg-none hover:backdrop-blur-2xl px-3 py-2.5 rounded`}
                  >
                    Мэдээ
                  </div>
                </a>
              </Link>
              <Link href="/schools">
                <a>
                  <div
                    className={`${
                      pathname === '/schools' || pathname === '/schools/[value]'
                        ? 'bg-white/20'
                        : pathname === '/' && isOpenNavbar
                        ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                        : 'hover:bg-white/30'
                    } bg-none hover:backdrop-blur-2xl px-3 py-2.5 rounded`}
                  >
                    Сургуулиуд
                  </div>
                </a>
              </Link>{' '}
              <Link href="/faq">
                <a>
                  <div
                    className={`${
                      pathname === '/faq'
                        ? 'bg-white/20'
                        : pathname === '/' && isOpenNavbar
                        ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                        : 'hover:bg-white/30'
                    } bg-none hover:backdrop-blur-2xl px-3 py-2.5 rounded`}
                  >
                    Түгээмэл асуулт
                  </div>
                </a>
              </Link>
              <Link href="/attendance">
                <a>
                  <div
                    className={`${
                      pathname === '/attendance'
                        ? 'bg-white/20'
                        : pathname === '/' && isOpenNavbar
                        ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                        : 'hover:bg-white/30'
                    } bg-none hover:backdrop-blur-2xl px-3 py-2.5 rounded`}
                  >
                    Сургалтын ирц
                  </div>
                </a>
              </Link>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <PhoneCall open={false} />

            <div className="hidden md:flex items-center ml-2">
              {isLoggedIn ? (
                <Profile open={true} student={currentUser?.student} />
              ) : (
                <>
                  <Link href={`/auth/login`} as={'/auth/login'}>
                    <a
                      className={`flex items-center text-sm text-white font-semibold backdrop-blur-2xl px-3 py-2.5 rounded-lg ${
                        pathname === '/'
                          ? 'bg-bluec'
                          : 'border border-white/40 hover:bg-white/20'
                      }`}
                    >
                      <UserIcon className="h-4 mr-1" />
                      {'Нэвтрэх'}
                    </a>
                  </Link>
                </>
              )}
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className={`inline-flex items-center justify-center px-2 py-1 rounded ${
                  pathname === '/' && isOpenNavbar ? 'text-bluec' : 'text-white'
                }`}
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <Bars3BottomLeftIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
        <div
          onClick={() => setIsOpen(false)}
          className={`${
            isOpen ? 'h-screen' : ''
          } top-0 absolute w-full flex justify-end backdrop-blur-2xl bg-black/60`}
        >
          <Transition
            show={isOpen}
            className=""
            enter="transition ease-in-out duration-700 transform"
            enterFrom="translate-x-full" // Start off-screen to the right
            enterTo="translate-x-0" // Slide to the center of the screen
            leave="transition ease-in-out duration-0 transform"
            leaveFrom="translate-x-0" // Start from the center of the screen
            leaveTo="translate-x-full" // Slide out to the right
          >
            {ref => (
              <div
                className="flex justify-center w-72 sm:w-96 md:hidden h-screen bg-white text-sm text-text"
                id="mobile-menu"
              >
                <div ref={ref} className="w-full">
                  <div className="flex justify-between items-center py-5 px-6 border-b">
                    <div className="text-sm">{'ҮНДСЭН ЦЭС'}</div>
                    <button onClick={() => setIsOpen(!isOpen)}>
                      <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-text" />
                    </button>
                  </div>
                  <div className="py-4 border-b-2 border-opacity-40 cursor-pointer text-text font-semibold">
                    {isLoggedIn ? (
                      <Link href="/user">
                        <div className="flex items-center justify-between bg-disabled mx-3 py-4 px-3 hover:border-hover rounded">
                          <div className="text-bluec flex items-center gap-2">
                            <UserCircleIcon className="h-6" />
                            <div>
                              <span>
                                <b className="capitalize">
                                  {currentUser?.student?.last_name + ' '}
                                </b>{' '}
                                {currentUser?.student?.first_name?.toUpperCase()}
                              </span>
                              <p className="text-xs font-normal">
                                {currentUser?.student_type_name}
                              </p>
                            </div>
                          </div>
                          <div>
                            <ChevronRightIcon className="h-4 w-4" />
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <>
                        <Link href={`/auth/login`} as={'/auth/login'}>
                          <a className="hover:bg-white/10 text-text font-normal mx-3 px-3 py-2 rounded flex items-center gap-2">
                            <UserIcon className="h-4" />
                            <span>{'Нэвтрэх'}</span>
                          </a>
                        </Link>
                        <div
                          onClick={() => setIsRegister(true)}
                          className="hover:bg-white/10 text-text font-normal mx-3  px-3 py-2 rounded flex items-center gap-2"
                        >
                          <PencilIcon className="h-4" />
                          <span>{'Бүртгүүлэх'}</span>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="px-3 mt-4 my-2">
                    {isLoggedIn && (
                      <>
                        <hr className="my-4 border-dashed" />
                        <Link href="/step">
                          <a target="_blank">
                            <div
                              className={`flex items-center gap-2 text-sm p-3 rounded ${
                                pathname === '/step'
                                  ? 'bg-disabled text-bluec'
                                  : 'hover:bg-white/10 hover:text-bluec'
                              }`}
                            >
                              <ChartBarIcon className="h-4 w-4" />
                              Алхам
                            </div>
                          </a>
                        </Link>
                        <Link href="/interesting-news">
                          <a target="_blank">
                            <div
                              className={`flex items-center gap-2 text-sm p-3 rounded text-text ${
                                pathname === '/interesting-news'
                                  ? 'bg-disabled text-bluec'
                                  : 'hover:bg-white/10 hover:text-bluec'
                              }`}
                            >
                              <BeakerIcon className="h-4 w-4" />
                              Зөвлөгөө
                            </div>
                          </a>
                        </Link>
                        <Link href="/mortgage">
                          <a target="_blank">
                            <div
                              className={`flex items-center gap-2 text-sm p-3 rounded ${
                                pathname === '/mortgage'
                                  ? 'bg-disabled text-bluec'
                                  : 'hover:bg-white/10 hover:text-bluec'
                              }`}
                            >
                              <DocumentIcon className="h-4 w-4" />
                              Барьцаа
                            </div>
                          </a>
                        </Link>{' '}
                        <Link href="/point">
                          <a target="_blank">
                            <div
                              className={`flex items-center gap-2 text-sm p-3 rounded ${
                                pathname === '/point'
                                  ? 'bg-disabled text-bluec'
                                  : 'hover:bg-white/10 hover:text-bluec'
                              }`}
                            >
                              <StarIcon className="h-4 w-4" /> Дүн
                            </div>
                          </a>
                        </Link>
                        <Link href="/contract">
                          <a target="_blank">
                            <div
                              className={`flex items-center gap-2 text-sm p-3 rounded ${
                                pathname === '/contract'
                                  ? 'bg-disabled text-bluec'
                                  : 'hover:bg-white/10 hover:text-bluec'
                              }`}
                            >
                              <ClipboardDocumentCheckIcon className="h-4 w-4" />
                              Гэрээ
                            </div>
                          </a>
                        </Link>
                        <Link href="/sim">
                          <a target="_blank">
                            <div
                              className={`flex items-center gap-2 text-sm p-3 rounded ${
                                pathname === '/sim'
                                  ? 'bg-disabled text-bluec'
                                  : 'hover:bg-white/10 hover:text-bluec'
                              }`}
                            >
                              <ChatIconSolid className="h-4 w-4" />
                              Сим
                            </div>
                          </a>
                        </Link>
                        <Link href="/comment">
                          <a target="_blank">
                            <div
                              className={`flex items-center gap-2 text-sm p-3 rounded ${
                                pathname === '/comment'
                                  ? 'bg-disabled text-bluec'
                                  : 'hover:bg-white/10 hover:text-bluec'
                              }`}
                            >
                              <ChatIconSolid className="h-4 w-4" />
                              Сэтгэгдэл
                            </div>
                          </a>
                        </Link>
                      </>
                    )}
                  </div>
                  <hr />

                  <div className="px-3 mt-4 mb-2">
                    <Link href="/">
                      <a>
                        <div
                          className={`flex items-center text-sm px-3 py-2 rounded text-text ${
                            pathname === '/'
                              ? 'bg-disabled text-bluec'
                              : 'hover:bg-white/10 hover:text-bluec'
                          }`}
                        >
                          Нүүр
                        </div>
                      </a>
                    </Link>
                    <Link href="/news">
                      <a>
                        <div
                          className={`${
                            pathname === '/news' || pathname === '/news/[value]'
                              ? 'bg-white/20'
                              : pathname === '/' && isOpenNavbar
                              ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                              : 'hover:bg-white/30'
                          } bg-none hover:backdrop-blur-2xl px-3 py-2.5 rounded`}
                        >
                          Мэдээ
                        </div>
                      </a>
                    </Link>
                    <Link href="/schools">
                      <a>
                        <div
                          className={`${
                            pathname === '/schools' ||
                            pathname === '/schools/[value]'
                              ? 'bg-white/20'
                              : pathname === '/' && isOpenNavbar
                              ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                              : 'hover:bg-white/30'
                          } bg-none hover:backdrop-blur-2xl px-3 py-2.5 rounded`}
                        >
                          Сургуулиуд
                        </div>
                      </a>
                    </Link>{' '}
                    {CompanyInfo?.map(company => (
                      <Link key={company?.id} href={company?.head}>
                        <a target="_blank">
                          <p className="text-sm p-3 hover:bg-white/10 hover:text-bluec rounded text-text">
                            {company?.title}
                          </p>
                        </a>
                      </Link>
                    ))}
                    <Link href="/attendance">
                      <a>
                        <div
                          className={`${
                            pathname === '/attendance'
                              ? 'bg-white/20'
                              : pathname === '/' && isOpenNavbar
                              ? 'hover:bg-bluec/90 hover:text-white text-bluec'
                              : 'hover:bg-white/30'
                          } bg-none hover:backdrop-blur-2xl px-3 py-2.5 rounded`}
                        >
                          Сургалтын ирц
                        </div>
                      </a>
                    </Link>
                  </div>
                  <hr />
                  {isLoggedIn && (
                    <div
                      onClick={() => logOut()}
                      className="cursor-pointer px-3 mt-4 "
                    >
                      <p className="text-sm p-3 bg-red-50 rounded text-red-400">
                        Системээс гарах
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Transition>
          {isRegister && <RegisterModal />}
        </div>
      </nav>
    </div>
  );
}
